module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.microincome.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132318148-4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MicroIncome.com","short_name":"Micro Income","start_url":"/","background_color":"#000000","theme_color":"#FFE072","display":"minimal-ui","icon":"src/images/Icon@3x.png","include_favicon":true,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"a9c66c5e6e1179f26f3201b248f698eb"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
